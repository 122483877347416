import React, { FC, memo } from 'react';
import { Link } from 'gatsby';
import { RowMain } from '../../layout/RowMain';

export type Props = {
	showImage?: boolean;
};

const Banner: FC<Props> = memo(({ showImage }) => (
	<section className="s-banner">
		<RowMain>
			<div className="s-banner__inner u-bg-color-blue-lightest">
				<h2 className="s-banner__title u-color-blue u-text-center">
					<span className="u-color-blue-light">Jak zrychlit váš web?</span>
					<small className="u-block">Objednejte si hloubkový audit</small>
					{/* 					<small className="u-block">
						<Link to="/audit#order">Objednejte si</Link> od nás hloubkový audit <br /> rychlosti
						a&nbsp;doporučení k&nbsp;úpravám.
					</small> */}
				</h2>
				<p className="s-banner__annot">
					<Link to="/audit" className="btn">
						<span className="btn__text">Více o&nbsp;auditu vašeho webu</span>
					</Link>
				</p>
				{showImage && (
					<figure className="u-text-center u-mb0">
						<img
							data-src="/img/zrychleni.svg"
							alt="Audit vašeho webu"
							width="614"
							height="403"
							className="lazyload"
						/>
						<noscript>
							<img src="/img/zrychleni.svg" alt="Audit vašeho webu" width="614" height="403" />
						</noscript>
					</figure>
				)}
			</div>
		</RowMain>
	</section>
));

Banner.displayName = 'Banner';

export default Banner;
